import React, { FC } from 'react';
import { parseBoolean } from 'gatsby-theme-gaviscon/src/utils/parseHelpers';
import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import BreadCrumbsItem from 'gatsby-theme-gaviscon/src/components/common/BreadCrumbs/BreadCrumbsItem';
import { IBreadCrumbs } from 'gatsby-theme-gaviscon/src/components/common/BreadCrumbs/models';
import 'gatsby-theme-gaviscon/src/components/common/BreadCrumbs/BreadCrumbs.scss';
import './BreadCrumbsOverride.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({ data, showBreadcrumbs }) => {
  const { isMobile} = useScreenRecognition();

  return parseBoolean(showBreadcrumbs) ? (
    <ul className="gs-breadcrumbs container-fluid">
      {!isMobile && data.map((item, index) => (
        <BreadCrumbsItem key={item.name} {...item} isActive={index === data.length - 1} />
      ))}
    </ul>
  ) : null;
};

export default BreadCrumbs;
